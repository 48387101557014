//font
$font: 'Circular Std Book', sans-serif;
$font-bold: 'Circular Std Bold', sans-serif;


//color
$light: #ebebeb;
$lighter: #d2d2d2;
$lightest: #ffffff;
$dark: #6e6e6e;
$darker: #2d2d2d;
$darkest: #141414;

:root {
  --background: #ffffff;
  --textcolor: #6e6e6e;
  --headercolor: #061b22;
}
html[data-theme="dark"] {
  // --background: #141414;
  --background: #0d1117;
  --textcolor: #888888;
  --textsecondary: #586069;
  --highlight: #ffffff;
  // --lightbackground: #1C1C1C;
  --lightbackground: #061b22;
  --shadow: rgba(149, 157, 165, 0.2);
  --textcoloractive: #ffffff;
  --headercolor: #061b22;
  --darkbackground: #0d1117;
  --navtext: #8b949e;
  --navtexthover: #c9d1d9;
  --textblue: #0366d6;
  --textyellow: #FFD347;
}
html[data-theme="light"] {
  --background: #ffffff;
  --textcolor: #6e6e6e;
  --textsecondary: #586069;
  --highlight: #141414;
  --lightbackground: #F3F3F3;
  --shadow: rgba(51, 51, 51, 0.2);
  --textcoloractive: #ffffff;
  --headercolor: #061b22;
  --darkbackground: #0d1117;
  --navtext: #8b949e;
  --navtexthover: #c9d1d9;
  --textblue: #0366d6;
  --textyellow: #FFD347;
}

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
  margin: calc(2rem - .14285714em) 0 1rem;
  &:first-child {
    margin-top: -.14285714em;
  }
}

h1, .h1 {
   @include fluid-type(30px, 60px);
}

h2, .h2 {
   @include fluid-type(22px, 32px);
}

h3, .h3 {
   @include fluid-type(18px, 25px);
}

p, .p {
   @include fluid-type(16px, 20px);
   line-height: 1.5em;
   margin-bottom: 1em;
}

ul, ol {
   @include fluid-type(16px, 20px);
   line-height: 1.5em;
   margin-bottom: 1em;
}

