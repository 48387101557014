@import "imports.scss";

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');
@font-face {
    font-family: 'Circular Std Book';
    src: url('../fonts/CircularStd-Book.eot'); /* IE9 Compat Modes */
    src: url('../fonts/CircularStd-Book.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/CircularStd-Book.woff') format('woff'), /* Pretty Modern Browsers */
         url('../fonts/CircularStd-Book.ttf')  format('truetype'), /* Safari, Android, iOS */
         url('../fonts/CircularStd-Book.svg') format('svg'); /* Legacy iOS */
}
@font-face {
    font-family: 'Circular Std Bold';
    src: url('../fonts/CircularStd-Bold.eot'); /* IE9 Compat Modes */
    src: url('../fonts/CircularStd-Bold.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/CircularStd-Bold.woff') format('woff'), /* Pretty Modern Browsers */
         url('../fonts/CircularStd-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
         url('../fonts/CircularStd-Bold.svg') format('svg'); /* Legacy iOS */
}
body {
  font-family: 'Circular Std Book', sans-serif;
}

html {
  background-color: var(--background);
  color: var(--textcolor);
}
body {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Page Loader */
.js .loading::before {
  content: '';
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  background: var(--background);
}

.js .loading::after {
  content: '';
  position: fixed;
  z-index: 10000;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: -25px 0 0 -25px;
  pointer-events: none;
  background: var(--textyellow);
  animation: loaderAnim 0.8s ease-out infinite alternate forwards;
}

@keyframes loaderAnim {
  to {
    transform: translate3d(0,-100px,0);
  }
}
.highlight {
  color: var(--highlight);
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 2em;
  @include media(tablet) {
    padding: 0 3em;
  }
  @include media(tablet-wide) {
    padding: 0 5em;
  }
  &.container--small {
    max-width: 700px;
  }
}


.alignfull {
  width: 100vw;
  margin: 5em calc(50% - 50vw);
}

.alignwide {
    width: 100vw;
    position: relative;
    margin: 5em calc(50% - 50vw);
    left: 0;
    @include media(tablet-wide) {
      width: calc(100% + 20vw);
      left: -10vw;
      margin: 5em 0;
    }
}
.image {
    width: 100%;
    height: auto;
    margin-top: 5em;
    margin-bottom: 5em;
}
footer {
  margin-top: 5em;
  padding: 4em 2em;
  background-color: var(--lightbackground);
  .footer__inner {
    text-align: center;
  }
  h3 {
    color: var(--textsecondary);
  }
  .link__group {
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      padding: 1em;
      max-width: 3.5em;
      svg {
        width: 100%;
        height: auto;
        transition: all 300ms ease;
        opacity: 1;
      }
      &:hover {
        svg {
          opacity: 75%;
          transition: all 300ms ease;
        }
      }
    }
  }
}
img {
  max-width: 100%;
}
figcaption {
  max-width: 100%;
  text-align: center;
  padding-bottom: 0.25em;
}
ul {
  list-style: none;
  padding-left: 1em;
  margin: 3em 0;
  li {
    margin-bottom: 1.5em;
    position: relative;
    padding: 5px 0;
    &:after {
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      left: -1em;
      background-color: rgb(255, 222, 67);
      width: 4px;
      border-radius: 10px;
    }
  }
}

article {
  .heading-intro {
    margin-top: 5em;
  }
  img {
    box-shadow: var(--shadow) 0px 8px 24px;
  }
}

.main-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1em 2.5em;
  width: 100%;
  height: 70px;
  position: fixed;
  background-color: var(--headercolor);
  z-index: 99;
  a {
    color: var(--navtext);
    transition: all 300ms ease;
    &:hover{
      color: var(--navtexthover);
      transition: all 300ms ease;
    }
    &.logo {
      margin-right: auto;
      @include fluid-type(18px, 22px);
      text-decoration: none;
    }
    &:not(.logo) {
      @include fluid-type(15px, 20px);
      padding: 15px 10px;
      text-decoration: none;
    }
    &.active {
      color: var(--textcoloractive);
      transition: all 300ms ease;
    }
  }
}


.site-link {
  text-align: center;
  margin: 4em auto -3em;
  @include fluid-type(18px, 22px);
  letter-spacing: 5px;
  a {
    color: var(--highlight);
    transition: all 300ms ease;
    opacity: 1;
    &:hover {
      opacity: 0.75;
      transition: all 300ms ease;
    }
  }
}
// swup animation:
.transition-fade {
  transition: 0.4s;
  opacity: 1;
}

html.is-animating .transition-fade {
  opacity: 0;
}


.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.hero {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 70px;
  @include media(tablet-wide) {
    height: 100vh;
    min-height: 700px;
  }
  .hero__inner {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    padding: 3em;
    display: flex;
    align-items: center;
  }
  .hero__inner--detail {
    // flex-wrap: wrap;
    max-width: 1200px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2em;
    @include media(tablet-wide) {
      padding: 3em;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }
  }
  .hero__left {
    display: flex;
    flex-direction: column;
    padding: 2em;
    // max-width: 300px;
    margin: 0 auto;
    box-sizing: border-box;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
    h1 {
      @include fluid-type(18px, 25px);
    }
    p {
      @include fluid-type(14px, 16px);
      font-style: italic;
    }
    strong {
      font-style: normal;
      margin: 1em 0 0.5em;
      @include fluid-type(12px, 14px);
    }
    svg {
      max-width:  100%;
    }
    @include media(tablet-wide) {
        flex: 1;
    }
  }
  .hero__right {
    flex: 1 1 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    img {
      max-width: 100%;
      box-shadow: var(--shadow) 0px 8px 24px;
    }
    @include media(tablet-wide) {
        flex: 1;
        padding-left: 2em;
    }
  }
}

.section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 10em 0;
  &:first-of-type {
    margin: 0 0 10em;
  }
  @include media(tablet-wide) {
    flex-wrap: nowrap;
  }
  &:nth-of-type(even) {
    flex-direction: row-reverse;
  }
  .section__block {
      flex-basis: 100%;
      @include media(tablet-wide) {
        flex-basis: 50%;
      }
  }
  .section__image {
    display: flex;
    img {
      max-width: 100%;
      transition: all 300ms ease;
    }
    a {
      &:hover {
        img {
          transition: all 300ms ease;
          transform: scale(1.025);
        }
      }
    }
  }
  .section__text {
    padding: 2em;
    a {
      position: relative;
      text-decoration: none;
      color: var(--highlight);
      &:after {
        bottom: -5px;
        content: "";
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        background: var(--highlight);
        transition: width .3s ease 0s,left .3s ease 0s;
        width: 0;
      }
      &:hover {
        &:after {
          width: 100%;
        }
      }
    }
  }
}


.intro {
  margin-bottom: 5em;
  margin-top: 5em;
  p {
    @include fluid-type(15px, 18px);
    margin-bottom: 1.25em;
  }
  .title-intro {
    margin-bottom: 0.25em;
    @include fluid-type(15px, 18px);
  }
  span {
    font-family: $font-bold;
    padding-right: 2em;
    @include fluid-type(14px, 16px);
  }
  @include media(tablet-wide) {
    margin-top: 1em;
  }
}

.shadow {
    box-shadow: var(--shadow) 0px 8px 24px;
}

.toggle-btn {
  align-self: center;
  margin: 15px 0 15px 20px;
  #check {
    display: none;
  }
}
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50px;
  height: 25px;
  background-color: var(--darkbackground);
  transition: all 1s ease;
  position: relative;
  border-radius: 30px;
  cursor: pointer;
  .ball {
    width: 25px;
    height: 25px;
    background-color: var(--textcolor);
    transition: all 1s ease;
    position: absolute;
    border-radius: 50%;
    border: 2px solid var(--darkbackground);
  }
  .scenary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: all 1s ease;
    padding: 0px 5px 0px 5px;
    svg {
      width: 15px;
    }
  }
}

.icon-box-container {
  display: flex;
  justify-content: center;
  .icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2em;
    margin: 1em;
    svg {
      width: 100%;
      height: auto;
      max-width: 80px;
      fill: var(--highlight);
    }
    &.blue-fill {
      svg {
        fill: var(--textblue);
      }
    }
    &.yellow-fill {
      svg {
        fill: var(--textyellow);
      }
    }
  }
}

.block-revealer__element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--textcoloractive);
  pointer-events: none;
  opacity: 0;
}
